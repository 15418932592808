import React from "react";

import "./index.css";

const readingPlan2023 = 
  "/pdf/WACReadingPlan2023.pdf";

const readingPlan2024 =
  "/pdf/WACReadingPlan2024.pdf";

const readingPlan2024Dropbox = "https://www.dropbox.com/scl/fi/shp7nr9vpiv6snlg0lruf/WACReadingPlan2024b.pdf?rlkey=0kcn9hyyddaghplz8u2ak8niv&st=1fzw7r1f&dl=1";
const readingPlan2025Dropbox = "https://www.dropbox.com/scl/fi/7qk477h7amutruibvy83c/2025-WAC-Reading-Plan-Old-2024-2025-1.5-year-plan-Google-Sheets.pdf?rlkey=39dodcfl08be87pe4ch7awb7v&st=s3da7v5s&dl=1";

function ReadingPlanForTheYear() {
  return (
    <div className="DownloadReadingPlan__component">
      <a href={readingPlan2024Dropbox} download="wearechurchreadingPDF-2024" rel="noopener noreferrer">
        <h3>2024 Reading Plan PDF</h3>
      </a>
      <a href={readingPlan2025Dropbox} download="wearechurchreadingPDF-2025" rel="noopener noreferrer">
        <h3>2025 Reading Plan PDF</h3>
      </a>
      <br />
    </div>
  );
}

export default ReadingPlanForTheYear;